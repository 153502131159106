/* eslint-disable */
window.SystemJS = window.System

// See https://github.com/systemjs/systemjs/issues/1939
const originalResolve = System.resolve
const moduleMap = {}
System.resolve = function (name) {
  return originalResolve.apply(this, arguments).then(resolved => {
    moduleMap[name] = resolved;
    return resolved;
  });
}

window.getPublicPath = function (name) {
  const url = moduleMap[name]

  if (url) {
    let index = url.lastIndexOf('/js')
    if (index < 0) {
      index = url.lastIndexOf('/')
    }
    index++
    return url.slice(0, index);
  }

  throw Error(`Could not find url for module '${name}'`)
}